const content_list = [
    { content: 'All', 
      color: '#48456D'}, 
    { content: 'Humor', 
    color: '#CA5E8F'}, 
    { content: 'This is me', 
    color: '#336699'}, 
    { content: 'Helps me', 
    color: '#48456D'}, 
    { content: 'This is us', 
    color: '#F2903D'}, 
    { content: 'Feels', 
    color: '#48A798'}
]

export default content_list;